// #region Imports

// Fluent UI Imports
import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { SpinButton, ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { TooltipHost, ITooltipHostStyles, ITooltipProps } from '@fluentui/react/lib/Tooltip';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { IIconProps } from '@fluentui/react';
import { Separator } from '@fluentui/react/lib/Separator';
import { Text } from '@fluentui/react/lib/Text';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { useId } from '@fluentui/react-hooks';

// React Imports
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IAccountInfo } from 'react-aad-msal';

// ALP Component Imports
import { ListLoading } from '../components/ListLoading.Component';
import { RuleExpression } from '../components/RuleExpression.Component';
import { RuleOutputIcmRouting } from '../components/RuleOutputIcmRouting.Component';

// ALP Service Imports
import { EmailService } from '../services/Email.Service';
import { IcmService } from '../services/Icm.Service';
import { JsonSchemaService } from '../services/JsonSchema.Service';
import { RulesService } from '../services/Rules.Service';
import { SecurityService } from '../services/Security.Service';

// ALP Type Imports
import { IEmail, ILeafExpression, INodeExpression, IRule, RuleApprovalStatus, RuleType } from '../common/Types';
import { RuleExpressions } from '../common/Helpers';

// #endregion Imports

interface Props {
  accessToken?: string,
  isEditable?: boolean,
  accountInfo?: IAccountInfo
}

export const RuleDetailsPage: React.FC<Props> = (props) => {
  const { signalSourceId, id } = useParams();

  // #region States

  const [ruleShortName, setRuleShortName] = useState<string>('');
  const [ruleName, setRuleName] = useState<string>('');
  const [ruleTypeDropdownItem, setRuleTypeDropdownItem] = useState<IDropdownOption>();
  const [ruleDescription, setRuleDescription] = useState<string>('');
  const [ruleIsActive, setRuleIsActive] = useState<boolean>(false);
  const [rulePriority, setRulePriority] = useState<string>('100');
  const [ruleCreatedOn, setRuleCreatedOn] = useState<Date | undefined>(new Date());
  const [icmRoutingRuleOuputTenantId, setIcmRoutingRuleOuputTenantId] = useState<string>('');
  const [icmRoutingRuleOutputTeamId, setIcmRoutingRuleOutputTeamId] = useState<string>('');
  const [icmRoutingRuleOutputTeamName, setIcmRoutingRuleOutputTeamName] = useState<string>('');
  const [ruleOutputGeneric, setRuleOutputGeneric] = useState<string>('');
  const [ruleExpressionJsonRaw, setRuleExpressionJsonRaw] = useState<string>('');
  const [ruleExpressionJsonUi, setRuleExpressionJsonUi] = useState<string>('');
  const [ruleExpressionEditRawJson, setRuleExpressionEditRawJson] = useState<boolean>(!props.isEditable);
  const [ruleExpressionJsonSchema, setRuleExpressionJsonSchema] = useState<string>('');
  const [ruleSecurityPoolDropdownItem, setRuleSecurityPoolDropdownItem] = useState<IDropdownOption>();
  const [ruleSecurityPoolDropdownOptions, setRuleSecurityPoolDropdownOptions] = useState<IDropdownOption[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditable, setIsEditable] = useState<boolean>(props.isEditable === true);

  // #endregion States

  // #region Consts

  const navigate = useNavigate();

  const ruleIdToolTip = useId('ruleIdToolTip');
  const ruleIdId = useId('ruleId');
  const ruleIdTooltipCalloutProps = { gapSpace: 0, target: `#${ruleIdId}` };
  const ruleIdTooltipContent = `The unique identifier for a rule. 
    Each rule for a signal source must have a unique short name. 
    A short name should only contain alphaumeric characters and no whitespaces.`;

  const ruleNameToolTip = useId('ruleNameToolTip');
  const ruleNameId = useId('ruleName');
  const ruleNameTooltipCalloutProps = { gapSpace: 0, target: `#${ruleNameId}` };
  const ruleNameTooltipContent = 'A user friendly name for a rule.';

  const ruleTypeToolTip = useId('ruleTypeToolTip');
  const ruleTypeId = useId('ruleType');
  const ruleTypeTooltipCalloutProps = { gapSpace: 0, target: `#${ruleTypeId}` };
  const ruleTypeTooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <>
        <p>The type of rule. This defines what action will be performed on an alert if the rule evaluates truthfully.</p>
        <p>
          <b>IcmRouting:</b> Routes the corresponding IcM incident to a selected IcM service and team.<br />
          <b>IcmHoldingDuration:</b> For ALP internal use only.<br />
          <b>AlertPipelineHoldingDuration:</b> Noise reduction. For ALP internal use only.<br />
          <b>Suppression:</b> Suppresses the corresponding alert and prevents it from creating an IcM incident.
        </p>
      </>)
  };

  const ruleDescriptionToolTip = useId('ruleDescriptionToolTip');
  const ruleDescriptionId = useId('ruleDescription');
  const ruleDescriptionTooltipCalloutProps = { gapSpace: 0, target: `#${ruleDescriptionId}` };
  const ruleDescriptionTooltipContent = 'Description of a rule.';

  const ruleActiveToolTip = useId('ruleActiveToolTip');
  const ruleActiveId = useId('ruleActive');
  const ruleActiveTooltipCalloutProps = { gapSpace: 0, target: `#${ruleActiveId}` };
  const ruleActiveTooltipContent = `Check this box to make the rule active. 
    An inactive rule is disabled and will not have any affect on the alert processing.`;

  const rulePriorityToolTip = useId('rulePriorityToolTip');
  const rulePriorityId = useId('rulePriority');
  const rulePriorityTooltipCalloutProps = { gapSpace: 0, target: `#${rulePriorityId}` };
  const rulePriorityTooltipContent = `The priority dictates which rule should come into effect 
    if multiple rules evaluate truthfully on an alert. A rule with higher priority number will 
    get preference. If you are unsure if this rule will conflict with another one, it is 
    recommended to leave this to the default value.`;

  const ruleSecurityToolTip = useId('ruleSecurityToolTip');
  const ruleSecurityId = useId('ruleSecurity');
  const ruleSecurityTooltipCalloutProps = { gapSpace: 0, target: `#${ruleSecurityId}` };
  const ruleSecurityTooltipContent = `The security pool within the signal source which should 
    have access to view/modify/delete this rule. If you are unsure of which security pool to 
    select, it is recommended to leave this to the default value.`;

  const ruleOutputToolTip = useId('ruleOutputToolTip');
  const ruleOutputId = useId('ruleOutput');
  const ruleOutputTooltipCalloutProps = { gapSpace: 0, target: `#${ruleOutputId}` };
  const ruleOutputTooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <>
        <p>Rule Output. This defines the action of created Rule.</p>
        <p>
          <b>IcmRouting:</b> Manually enter the full name of the Owning Service. <br />
          <b>IcmHoldingDuration:</b> Manually enter the JSON fields for holding duration. <br />
          <b>AlertPipelineHoldingDuration:</b> Manually enter the JSON fields for holding duration. <br />
          <b>Suppression:</b> Not applicable.
        </p>
      </>)
  };

  const ruleEditJsonToolTip = useId('ruleEditJsonToolTip');
  const ruleEditJsonId = useId('ruleEditJson');
  const ruleEditJsonTooltipCalloutProps = { gapSpace: 0, target: `#${ruleEditJsonId}` };
  const ruleEditJsonTooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <>
        <p>
          If selected &apos;No&apos;, use the controls below to add the condition/expression for this rule.<br /><br />
          Click on &apos;+ Rule&apos; to add a single line rule.
          In a single rule, select an available field from the left hand side dropdown,
          an operator to work on the selected field from the middle dropdown, and type a desired value
          in the right hand side text box.<br /><br />
          Click on &apos;+ Group&apos; after selecting multiple rules to group the rules by a logical operator.<br /><br />
          From the left hand side dropdown, if a field is selected which corresponds to a collection of objects
          rather than a single value, a new group will automatically be created with a collection-relevant operator,
          and the left hand side dropdown values will change to single fields for objects that make up the collection.<br /><br />
        </p>
        <p>
          If selected &apos;Yes&apos;, the expression JSON can directly be modified instead of using the UI controls.
          Only recommended for advanced users.
        </p>
      </>)
  };

  const editIcon: IIconProps = { iconName: 'Edit' };
  const stackTokens: IStackTokens = { childrenGap: 18 };
  const innerStackTokens: IStackTokens = { childrenGap: 2 };
  const ruleIdTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 400 } };
  const ruleNameTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 400 } };
  const ruleTypeDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const ruleDescriptionTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 600 } };
  const ruleOutputGenericTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 600 } };
  const ruleExpressionTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 600 } };
  const prioritySpinStyles: Partial<ISpinButtonStyles> = { spinButtonWrapper: { width: 75 } };
  const ruleSecurityPoolDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const ruleTypeDropdownOptions = [
    { key: 'IcmRouting', text: RuleType.IcmRouting as string },
    { key: 'IcmHoldingDuration', text: RuleType.IcmHoldingDuration as string },
    { key: 'AlertPipelineHoldingDuration', text: RuleType.AlertPipelineHoldingDuration as string },
    { key: 'Suppression', text: RuleType.Suppression as string }
  ];

  const runValidations = (): void => {
    if (ruleShortName &&
      signalSourceId &&
      ruleName &&
      ruleTypeDropdownItem &&
      ruleSecurityPoolDropdownItem &&
      ((ruleExpressionEditRawJson && ruleExpressionJsonRaw) || (!ruleExpressionEditRawJson && ruleExpressionJsonUi))) {
      if (ruleTypeDropdownItem.key === 'IcmRouting') {
        if (icmRoutingRuleOuputTenantId && icmRoutingRuleOutputTeamId) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  };

  const getOrCreateIcmRoutingRule = async (accessToken: string): Promise<boolean> => {
    if (icmRoutingRuleOuputTenantId && icmRoutingRuleOutputTeamId) {
      const existingRoutingRules = await IcmService.getRoutingRulesByDestinationTenantAndTeam(
        accessToken,
        process.env.REACT_APP_ALP_ICM_TENANT_ID as string,
        icmRoutingRuleOuputTenantId,
        icmRoutingRuleOutputTeamId);

      let routingId = '';
      if (existingRoutingRules && existingRoutingRules.length > 0) {
        const routingIdConditionedRules = existingRoutingRules
          .filter(r => {
            return r.conditions?.filter(c => c.field === 'incident.RoutingId')?.length > 0;
          });

        if (routingIdConditionedRules && routingIdConditionedRules.length > 0) {
          routingId = routingIdConditionedRules[0].conditions.filter(c => c.field === 'incident.RoutingId')[0].value[0];
        }
      }

      if (routingId === '') {
        const routingIdToCreate = 'alpportal://' + icmRoutingRuleOuputTenantId + '-' + icmRoutingRuleOutputTeamId;
        console.log('No IcM routing rules found for ' +
          icmRoutingRuleOuputTenantId + '/' + icmRoutingRuleOutputTeamId +
          '. Attempting to create ' + routingIdToCreate);
        console.log('routing rule creation body accesstoken - ' + accessToken +
          'Number(rulePriority)-' + Number(rulePriority) + 'routingIdToCreate-' +
          routingIdToCreate + 'icmRoutingRuleOuputTenantId - ' + icmRoutingRuleOuputTenantId +
          'icmRoutingRuleOutputTeamId - ' + icmRoutingRuleOutputTeamId);

        const createdRoutingRule = await IcmService.createRoutingRule(
          accessToken,
          process.env.REACT_APP_ALP_ICM_TENANT_ID as string,
          Number(rulePriority),
          routingIdToCreate,
          icmRoutingRuleOuputTenantId,
          icmRoutingRuleOutputTeamId,
          icmRoutingRuleOutputTeamName
        );

        if (createdRoutingRule && createdRoutingRule.length > 0) {
          routingId = routingIdToCreate;
        }
      }

      if (!routingId) {
        console.error('Failed to create IcM routing rule');
        return false;
      } else {
        if (isValid) {
          const tenantAdmins = await IcmService.getTenantAdmins(accessToken, icmRoutingRuleOuputTenantId);
          const ruleToCreate: IRule = {
            shortName: ruleShortName as string,
            source: signalSourceId as string,
            name: ruleName as string,
            description: ruleDescription ? ruleDescription as string : '',
            isActive: ruleIsActive === true,
            priority: rulePriority ? Number(rulePriority) : 0,
            ruleType: (RuleType as any)[(ruleTypeDropdownItem as IDropdownOption).key?.toString()],
            expressionJson: ruleExpressionEditRawJson ? ruleExpressionJsonRaw : ruleExpressionJsonUi,
            securityPools: [(ruleSecurityPoolDropdownItem as IDropdownOption).key?.toString()],
            output: routingId,
            teamAdminContact: tenantAdmins,
            approvalStatus: RuleApprovalStatus.PendingApproval
          };
          let icmRoutingRuleOutputTeamName;
          const icmTeams = await IcmService.getAllTeams(accessToken, icmRoutingRuleOuputTenantId as string);
          icmTeams?.forEach(icmTeam => {
            if (icmTeam.id === icmRoutingRuleOutputTeamId) {
              icmRoutingRuleOutputTeamName = icmTeam.name;
            }
          });
          const requestorName = props.accountInfo?.account.name;
          const emailContent: IEmail = {
            toAddresses: tenantAdmins,
            subject: `[ACTION REQUIRED] Requires your Approval-New Routing Rule created(Destination Queue-${icmRoutingRuleOutputTeamName})`,
            reqAlias: requestorName as string,
            destQueue: icmRoutingRuleOutputTeamName as unknown as string,
            createdOn: ruleCreatedOn as Date,
            signalSource: signalSourceId as string
          };

          const createdRules = id
            ? await RulesService.createOrUpdate(accessToken, signalSourceId as string, [ruleToCreate])
            : await RulesService.create(accessToken, signalSourceId as string, [ruleToCreate]);

          if (createdRules && createdRules.length > 0) {
            await EmailService.SendEmail(accessToken, signalSourceId as string, [emailContent]);
            return true;
          } else {
            console.error('Failed creating rule with portal API.');
            return false;
          }
        } else {
          console.error('Validations failed');
          return false;
        }
      }
    }

    return false;
  };

  const createGenericRule = async (accessToken: string): Promise<boolean> => {
    if (isValid) {
      const ruleToCreate: IRule = {
        shortName: ruleShortName as string,
        source: signalSourceId as string,
        name: ruleName as string,
        description: ruleDescription ? ruleDescription as string : '',
        isActive: ruleIsActive === true,
        priority: rulePriority ? Number(rulePriority) : 0,
        ruleType: (RuleType as any)[(ruleTypeDropdownItem as IDropdownOption).key?.toString()],
        expressionJson: ruleExpressionEditRawJson ? ruleExpressionJsonRaw : ruleExpressionJsonUi,
        securityPools: [(ruleSecurityPoolDropdownItem as IDropdownOption).key?.toString()],
        output: ruleOutputGeneric
      };

      const createdRules = id
        ? await RulesService.createOrUpdate(accessToken, signalSourceId as string, [ruleToCreate])
        : await RulesService.create(accessToken, signalSourceId as string, [ruleToCreate]);

      if (createdRules && createdRules.length > 0) {
        return true;
      } else {
        console.error('Failed creating rule with portal API.');
        return false;
      }
    } else {
      console.error('Validations failed');
      return false;
    }
  };

  // #endregion Consts

  // #region Event Handlers

  const onRuleShortNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setRuleShortName(newValue || '');
  };

  const onRuleNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setRuleName(newValue || '');
  };

  const onRuleTypeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setRuleTypeDropdownItem(option);
  };

  const onRuleDescriptionChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setRuleDescription(newValue || '');
  };

  const onRuleIsActiveChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setRuleIsActive(checked === true);
  };

  const onRulePriorityChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string): void => {
    setRulePriority(newValue || '0');
  };

  const onIcmRoutingRuleOuputTenantIdChange = (icmTenantId: string): void => {
    setIcmRoutingRuleOuputTenantId(icmTenantId);
  };

  const onIcmRoutingRuleOuputTeamIdChange = (icmTeamId: string, icmTeamName: string): void => {
    setIcmRoutingRuleOutputTeamId(icmTeamId);
    setIcmRoutingRuleOutputTeamName(icmTeamName);
  };

  const onRuleOutputGenericChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setRuleOutputGeneric(newValue || '');
  };

  const onRuleExpressionJsonChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setRuleExpressionJsonRaw(newValue || '');
  };

  const onRuleExpressionUiChange = (jsonExpression: string): void => {
    setRuleExpressionJsonUi(jsonExpression || '');
  };

  const onRuleExpressionEditRawJsonChange = (event: React.MouseEvent<HTMLElement>, checked?: boolean): void => {
    const changingToRawJson = checked === true;
    if (changingToRawJson) {
      setRuleExpressionJsonRaw(ruleExpressionJsonUi);
    }

    setRuleExpressionEditRawJson(changingToRawJson);
  };

  const onRuleSecurityPoolChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setRuleSecurityPoolDropdownItem(option);
  };

  const editClicked = (): void => {
    setIsEditable(true);
    navigate('edit');
    setRuleExpressionEditRawJson(false);
  };

  const cancelClicked = (): void => {
    navigate('/rules/' + signalSourceId);
  };

  const saveClicked = (): void => {
    if (props.accessToken) {
      if (ruleExpressionEditRawJson && ruleExpressionJsonRaw) {
        try {
          const expressionJson = ruleExpressionJsonRaw
            ? RuleExpressions.generateKeys(JSON.parse(ruleExpressionJsonRaw) as (INodeExpression | ILeafExpression))
            : undefined;
          if (!RuleExpressions.isExpressionValid(RuleExpressions.stripKeys(expressionJson))) {
            alert('Invalid JSON expression');
            return;
          }
        } catch (e) {
          alert('Invalid JSON expression');
          return;
        }
      }
      if (ruleTypeDropdownItem?.key === 'IcmRouting') {
        setIsSaving(true);
        getOrCreateIcmRoutingRule(props.accessToken)
          .then((success) => {
            if (!success) {
              alert('An error occured while creating a new routing rule. Please try again, or contact cce-wpe@microsoft.com');
            } else {
              navigate('/rules/' + signalSourceId + '?forcerefresh=true');
            }
          })
          .catch((error) => {
            console.error(error);
            alert('An error occured while creating a new routing rule. Please try again, or contact cce-wpe@microsoft.com');
          })
          .finally(() => {
            setIsSaving(false);
          });
      } else {
        setIsSaving(true);
        createGenericRule(props.accessToken)
          .then((success) => {
            if (!success) {
              alert('An error occured while creating a new generic rule. Please try again, or contact cce-wpe@microsoft.com');
            } else {
              navigate('/rules/' + signalSourceId + '?forcerefresh=true');
            }
          })
          .catch((error) => {
            console.error(error);
            alert('An error occured while creating a new generic rule. Please try again, or contact cce-wpe@microsoft.com');
          })
          .finally(() => {
            setIsSaving(false);
          });
      }
    } else {
      alert('Unauthorized. Please login again.');
    }
  };

  // #endregion Event Handlers

  useEffect(() => {
    runValidations();
  }, [ruleShortName,
    signalSourceId,
    ruleName,
    ruleTypeDropdownItem,
    ruleSecurityPoolDropdownItem,
    ruleIsActive,
    rulePriority,
    ruleExpressionJsonRaw,
    ruleExpressionJsonUi,
    ruleExpressionEditRawJson,
    icmRoutingRuleOuputTenantId,
    icmRoutingRuleOutputTeamId,
    icmRoutingRuleOutputTeamName
  ]);

  useEffect(() => {

    /**
     * Fetches data from ALP APIs
     */
    async function fetchFromService () {
      if (props.accessToken) {
        if (signalSourceId) {
          const securityPools = await SecurityService.getAll(props.accessToken, signalSourceId);
          const ruleSecurityPoolDropdownOptionsFromApi = [] as IDropdownOption[];
          securityPools?.forEach(pool => {
            ruleSecurityPoolDropdownOptionsFromApi.push({ key: pool.shortName, text: pool.name });
          });

          const schema = await JsonSchemaService.get(props.accessToken, signalSourceId);
          if (schema) {
            setRuleExpressionJsonSchema(schema.schema);
          }

          if (isEditable) {
            setRuleExpressionEditRawJson(!schema);
          }

          if (ruleSecurityPoolDropdownOptionsFromApi.length > 0) {
            setRuleSecurityPoolDropdownOptions(ruleSecurityPoolDropdownOptionsFromApi);
            setRuleSecurityPoolDropdownItem(ruleSecurityPoolDropdownOptionsFromApi[0]);
          }

          if (id) {
            const rule = await RulesService.get(props.accessToken, signalSourceId, id, true);
            if (rule) {
              setRuleShortName(rule.shortName);
              setRuleName(rule.name);
              setRuleDescription(rule.description);
              setRuleIsActive(rule.isActive);
              setRulePriority(rule.priority.toString());
              setRuleExpressionJsonRaw(rule.expressionJson);
              setRuleExpressionJsonUi(rule.expressionJson);
              setRuleCreatedOn(rule.createdOn);

              setRuleTypeDropdownItem(
                ruleTypeDropdownOptions.filter(o => { return o.key === rule.ruleType.toString(); })[0]);
              setRuleSecurityPoolDropdownItem(
                ruleSecurityPoolDropdownOptionsFromApi.filter(o => { return o.key.toString() === rule.securityPools[0]; })[0]);

              if (rule.ruleType === RuleType.IcmRouting) {
                const icmRoutingRule = await IcmService.getRoutingRulesByRoutingId(
                  props.accessToken,
                  process.env.REACT_APP_ALP_ICM_TENANT_ID as string,
                  rule.output);
                setIcmRoutingRuleOuputTenantId(icmRoutingRule[0].action.owningService.id);
                setIcmRoutingRuleOutputTeamId(icmRoutingRule[0].action.owningTeam.id);
                setIcmRoutingRuleOutputTeamName(icmRoutingRule[0].action.owningTeam.displayName);
              } else {
                setRuleOutputGeneric(rule.output);
              }
            }
          }
        }

        setIsLoading(false);
      }
    }

    fetchFromService();
  }, [props.accessToken]);

  // #region Render Content

  const loadingContent = (
    <div>
      <ListLoading
        groupCount={ 7 }
        groupSpacing={ 36 }
        rowCount={ 2 }
        rowSpacing={ 18 }
        shimmerSize={ 18 }
        rowRelativeLengths={ [3, 10] }
        rowMaxLengthInPercentage={ 70 } />
    </div>
  );

  const mainContent = (
    <Stack tokens={ stackTokens }>
      <Text variant='xLarge'>{ id ? ruleShortName : 'Create New Rule' }</Text>
      <div>
              {
                  isEditable
                    ? <></>
                    : <ActionButton iconProps={editIcon} allowDisabledFocus onClick={editClicked}>Edit</ActionButton>
              }
      </div>
      {
        id
          ? <></>
          : <Stack.Item>
            <Stack tokens={ innerStackTokens }>
              <Stack horizontal={ true }>
                <Text variant='medium'>
                  Short Name/ID
                </Text>
                <TooltipHost
                  content={ ruleIdTooltipContent }
                  id={ ruleIdToolTip }
                  calloutProps={ ruleIdTooltipCalloutProps }
                  closeDelay={ 500 }
                  styles={ tooltipHostStyles }>
                  <Text
                    variant='small'
                    aria-describedby={ ruleIdToolTip }
                    id={ ruleIdId }>
                    &nbsp;&nbsp;&#x1F6C8;
                  </Text>
                </TooltipHost>
              </Stack>
              <TextField
                key='ruleShortName'
                required
                disabled={ !isEditable }
                styles={ ruleIdTextStyles }
                value={ ruleShortName }
                onChange={ onRuleShortNameChange }>
              </TextField>
            </Stack>
          </Stack.Item>
      }
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Name
            </Text>
            <TooltipHost
              content={ ruleNameTooltipContent }
              id={ ruleNameToolTip }
              calloutProps={ ruleNameTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ ruleNameToolTip }
                id={ ruleNameId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <TextField
            key='ruleName'
            required
            disabled={ !isEditable }
            styles={ ruleNameTextStyles }
            value={ ruleName }
            onChange={ onRuleNameChange }>
          </TextField>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Type
            </Text>
            <TooltipHost
              tooltipProps={ ruleTypeTooltipProps }
              id={ ruleTypeToolTip }
              calloutProps={ ruleTypeTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ ruleTypeToolTip }
                id={ ruleTypeId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='ruleType'
            placeholder="Select a type"
            required
            disabled={ !isEditable }
            styles={ ruleTypeDropdownStyles }
            options={ ruleTypeDropdownOptions }
            selectedKey={ ruleTypeDropdownItem ? ruleTypeDropdownItem.key : undefined }
            onChange={ onRuleTypeChange }
          />
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Description
            </Text>
            <TooltipHost
              content={ ruleDescriptionTooltipContent }
              id={ ruleDescriptionToolTip }
              calloutProps={ ruleDescriptionTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ ruleDescriptionToolTip }
                id={ ruleDescriptionId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <TextField
            key='ruleDescription'
            disabled={ !isEditable }
            multiline
            rows={ 2 }
            styles={ ruleDescriptionTextStyles }
            value={ ruleDescription }
            onChange={ onRuleDescriptionChange }>
          </TextField>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Checkbox
              key='ruleIsActive'
              disabled={ !isEditable }
              checked={ ruleIsActive }
              onChange={ onRuleIsActiveChange }>
            </Checkbox>
            <Text variant='medium'>
              &nbsp;Active
            </Text>
            <TooltipHost
              content={ ruleActiveTooltipContent }
              id={ ruleActiveToolTip }
              calloutProps={ ruleActiveTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ ruleActiveToolTip }
                id={ ruleActiveId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Priority
            </Text>
            <TooltipHost
              content={ rulePriorityTooltipContent }
              id={ rulePriorityToolTip }
              calloutProps={ rulePriorityTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ rulePriorityToolTip }
                id={ rulePriorityId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <SpinButton
            key='rulePriority'
            defaultValue="0"
            disabled={ !isEditable }
            min={ 0 }
            step={ 1 }
            incrementButtonAriaLabel="Increase value by 1"
            decrementButtonAriaLabel="Decrease value by 1"
            styles={ prioritySpinStyles }
            value={ rulePriority }
            onChange={ onRulePriorityChange }
          />
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Security Pool
            </Text>
            <TooltipHost
              content={ ruleSecurityTooltipContent }
              id={ ruleSecurityToolTip }
              calloutProps={ ruleSecurityTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ ruleSecurityToolTip }
                id={ ruleSecurityId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='ruleSecurityPool'
            placeholder="Select a security pool"
            required
            disabled={ ruleSecurityPoolDropdownOptions.length < 2 || !isEditable }
            styles={ ruleSecurityPoolDropdownStyles }
            options={ ruleSecurityPoolDropdownOptions }
            selectedKey={ ruleSecurityPoolDropdownItem ? ruleSecurityPoolDropdownItem.key : undefined }
            onChange={ onRuleSecurityPoolChange }
          />
        </Stack>
      </Stack.Item>
      {
        ruleTypeDropdownItem?.key !== 'Suppression'
          ? <>
            <Stack.Item>
              <Stack horizontal={ true } >
                <Text variant='medium'>
                  Rule Output
                </Text>
                <TooltipHost
                  tooltipProps={ ruleOutputTooltipProps }
                  id={ ruleOutputToolTip }
                  calloutProps={ ruleOutputTooltipCalloutProps }
                  closeDelay={ 500 }
                  styles={ tooltipHostStyles }>
                  <Text
                    variant='small'
                    aria-describedby={ ruleOutputToolTip }
                    id={ ruleOutputId }>
                    &nbsp;&nbsp;&#x1F6C8;
                  </Text>
                </TooltipHost>
              </Stack>
              {
                ruleTypeDropdownItem?.key === 'IcmRouting'
                  ? <RuleOutputIcmRouting
                    key='ruleOutputIcmRouting'
                    { ...props }
                    disabled={ !isEditable }
                    onSelectIcmTenant={ onIcmRoutingRuleOuputTenantIdChange }
                    onSelectIcmTeam={ onIcmRoutingRuleOuputTeamIdChange }
                    icmTenantId={ icmRoutingRuleOuputTenantId }
                    icmTeamId={ icmRoutingRuleOutputTeamId }
                    icmTeamName={ icmRoutingRuleOutputTeamName }
                  >
                  </RuleOutputIcmRouting>
                  : <TextField
                    key='ruleOutputGeneric'
                    disabled={ !isEditable }
                    required
                    multiline
                    rows={ 3 }
                    styles={ ruleOutputGenericTextStyles }
                    value={ ruleOutputGeneric }
                    onChange={ onRuleOutputGenericChange }>
                  </TextField>
              }
            </Stack.Item>
          </>
          : <></>
      }
      <Separator alignContent="start">Rule Expression</Separator>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Toggle
              key='ruleExpressionJsonToggle'
              label="Edit Raw JSON"
              onText="Yes"
              offText="No"
              disabled={ !isEditable || !ruleExpressionJsonSchema }
              checked={ ruleExpressionEditRawJson }
              onChange={ onRuleExpressionEditRawJsonChange } />
            <TooltipHost
              tooltipProps={ ruleEditJsonTooltipProps }
              id={ ruleEditJsonToolTip }
              calloutProps={ ruleEditJsonTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ ruleEditJsonToolTip }
                id={ ruleEditJsonId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          {
            ruleExpressionEditRawJson
              ? <TextField
                key='ruleExpressionJson'
                disabled={ !isEditable }
                required
                multiline
                rows={ 3 }
                styles={ ruleExpressionTextStyles }
                value={ ruleExpressionJsonRaw }
                onChange={ onRuleExpressionJsonChange }>
              </TextField>
              : <RuleExpression
                key='ruleExpressionJsonUi'
                jsonExpression={ ruleExpressionJsonRaw }
                jsonSchema={ ruleExpressionJsonSchema }
                onChange={ onRuleExpressionUiChange }>
              </RuleExpression>
          }
        </Stack>
      </Stack.Item>
      <Separator></Separator>
      {
        isEditable
          ? <div>
            <Stack.Item>
              <Stack horizontal tokens={ stackTokens }>
                <DefaultButton text='Cancel' onClick={ cancelClicked } allowDisabledFocus />
                <PrimaryButton
                  text={ isSaving ? 'Saving' : 'Save' }
                  onClick={ saveClicked }
                  allowDisabledFocus
                  disabled={ isSaving || !isValid } />
              </Stack>
            </Stack.Item>
            <Separator></Separator>
          </div>
          : <div>
          </div>
      }
    </Stack>
  );

  // #endregion Render Content

  return (
    <div>
      {
        isLoading
          ? loadingContent
          : mainContent
      }
    </div>
  );
};
