/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// #region Imports

// Fluent UI Imports
import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
// import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { IIconProps } from '@fluentui/react';
import { Separator } from '@fluentui/react/lib/Separator';
import { Text } from '@fluentui/react/lib/Text';
import { useId } from '@fluentui/react-hooks';

// React Imports
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IAccountInfo } from 'react-aad-msal';

// ALP Component Imports
import { ListLoading } from '../components/ListLoading.Component';

// ALP Service Imports
import { AlertOnboardService } from '../services/AlertOnboard.Service';

// ALP Type Imports
import { IAlertOnboard, ServiceLines, SPLFeatures } from '../common/Types';

// #endregion Imports

interface Props {
  accessToken?: string,
  isEditable?: boolean,
  accountInfo?: IAccountInfo
}

export const AlertOnboardDetailsPage: React.FC<Props> = (props) => {
  const { id } = useParams();
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  // #region States

  const [scenarioName, setScenarioName] = useState<string>('');
  const [servicelineDropdownItem, setServicelineDropdownItem] = useState<IDropdownOption>();
  const [kquery, setScenarioKQuery] = useState<string>('');
  const [alerticmmapperName, setAlertIcmMapperName] = useState<string>('');
  const [seconds, setSeconds] = useState('');
  const [minutes, setMinutes] = useState('');
  const [hours, setHours] = useState('');
  const [days, setDays] = useState('');
  const [splfeatureDropdownItem, setSPLFeatureDropdownItem] = useState<IDropdownOption>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [severityDropdownItem, setSeverityDropdownItem] = useState<IDropdownOption>();
  const [createdOn, setCreatedOn] = useState<Date | undefined>(new Date());
  const [icmRoutingRuleDropdownItem, setIcmRoutingRuleDropdownItem] = useState<IDropdownOption>();
  const [icmCorrelationRuleDropdownItem, setIcmCorrelationRuleDropdownItem] = useState<IDropdownOption>();
  const [alertSuppressionRuleDropdownItem, setAlertSuppressionRuleDropdownItem] = useState<IDropdownOption>();
  const [icmEnrichmentRuleDropdownItem, setIcmEnrichmentRuleDropdownItem] = useState<IDropdownOption>();
  const [scenarioFalconAutomationDropdownItem, setIsFalconAutomation] = useState<IDropdownOption>();
  const [className, setClassName] = useState<string>('');
  const [eventName, setEventName] = useState<string>('');
  const [queryRegexError, setQueryRegexError] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [isRegexCheckFailed, setIsRegexCheckFailed] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(props.isEditable === true);

  // #endregion States

  // #region Consts

  const navigate = useNavigate();

  const scenarioIdToolTip = useId('scenarioIdToolTip');
  const scenarioIdId = useId('scenarioId');
  const scenarioIdTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioIdId}` };
  const scenarioIdTooltipContent = `The unique identifier for an alert scenario. 
    Each alert scenario for a serviceline must have a unique short name. 
    A short name should only contain alphaumeric characters and no whitespaces.`;

  const servicelineToolTip = useId('servicelineToolTip');
  const servicelineId = useId('serviceline');
  const servicelineTooltipCalloutProps = { gapSpace: 0, target: `#${servicelineId}` };
  const servicelineTooltipContent = 'Serviceline name for the alert onboard request.';
  const scenarioKQueryToolTip = useId('scenariokqueryToolTip');
  const scenarioKQueryId = useId('scenarioKQuery');
  const scenarioKQueryTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioKQueryId}` };
  const scenarioKQueryTooltipContent = 'KQL Query for alert scenario.';
  const scenarioIsActiveToolTip = useId('isActiveToolTip');
  const scenarioIsActiveId = useId('isActive');
  const scenarioIsActiveTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioIsActiveId}` };
  const scenarioIsActiveTooltipContent = `Check this box to make the alert onboard scenario active. 
    An inactive scenario is disabled and will not be picked up for polling IDL kusto to create incident tickets.`;
  const scenarioSeverityToolTip = useId('scenarioSeverityToolTip');
  const scenarioSeverityId = useId('scenarioSeverity');
  const scenarioSeverityTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioSeverityId}` };
  const scenarioSeverityTooltipContent = 'The severity value dictates the incident ticket severity which needs to be set';
  const scenarioAlertIcmMapperToolTip = useId('scenarioAlertIcmMapperToolTip');
  const scenarioAlertIcmMapperId = useId('scenarioAlertIcmMapper');
  const scenarioAlertIcmMapperTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioAlertIcmMapperId}` };
  const scenarioAlertIcmMapperTooltipContent = `The alert-incident field mapper defines the 
    mapping between alert schema vs incident ticket schema which needs to be set`;
  const scenarioPollingFreqToolTip = useId('scenarioPollingFreqToolTip');
  const scenarioPollingFreqId = useId('scenarioPollingFreq');
  const scenarioPollingFreqTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioPollingFreqId}` };
  const scenarioPollingFreqTooltipContent = `The scenario polling frequency value defines how many times the poller funcapp
    will connect to IDL Kusto to get the alerts for incident creation.`;
  const scenarioSPLFeatureToolTip = useId('scenarioSPLFeatureToolTip');
  const scenarioSPLFeatureId = useId('scenarioSPLFeature');
  const scenarioSPLFeatureTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioSPLFeatureId}` };
  const scenarioSPLFeatureTooltipContent = `The SmartPipeline feature list shows the various features which needs to be selected 
    for each scenario.`;
  const scenarioFalconAutomationToolTip = useId('scenarioFalconAutomationToolTip');
  const scenarioFalconAutomationId = useId('scenarioFalconAutomation');
  const scenarioFalconAutomationTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioFalconAutomationId}` };
  const scenarioFalconAutomationTooltipContent = `The Falcon Automation value defines if for this scenario Falcon automation is 
    needed or not.`;
  const scenarioIcmRoutingRuleTooltip = useId('scenarioIcmRoutingRuleToolTip');
  const scenarioIcmRoutingRuleId = useId('scenarioIcmRoutingRule');
  const scenarioIcmRoutingRuleTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioIcmRoutingRuleId}` };
  const scenarioIcmRoutingRuleTooltipContent = 'The ICM routing rule which needs to be set for this scenario.';
  const scenarioIcmCorrelationRuleTooltip = useId('scenarioIcmCorrelationRuleToolTip');
  const scenarioIcmCorrelationRuleId = useId('scenarioIcmCorrelationRule');
  const scenarioIcmCorrelationRuleTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioIcmCorrelationRuleId}` };
  const scenarioIcmCorrelationRuleTooltipContent = 'The ICM Correlation rule which needs to be set for this scenario.';
  const scenarioAlertSuppressionRuleTooltip = useId('scenarioAlertSuppressionRuleToolTip');
  const scenarioAlertSuppressionRuleId = useId('scenarioAlertSuppressionRule');
  const scenarioAlertSuppressionRuleTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioAlertSuppressionRuleId}` };
  const scenarioAlertSuppressionRuleTooltipContent = 'The Alert Suppression rule which needs to be set for this scenario.';
  const scenarioIcmEnrichmentRuleTooltip = useId('scenarioIcmEnrichmentRuleToolTip');
  const scenarioIcmEnrichmentRuleId = useId('scenarioIcmEnrichmentRule');
  const scenarioIcmEnrichmentRuleTooltipCalloutProps = { gapSpace: 0, target: `#${scenarioIcmEnrichmentRuleId}` };
  const scenarioIcmEnrichmentRuleTooltipContent = 'The ICM Enrichment rule which needs to be set for this scenario.';
  const editIcon: IIconProps = { iconName: 'Edit' };
  const redirectIcon: IIconProps = { iconName: 'OpenInNewTab' };
  const stackTokens: IStackTokens = { childrenGap: 18 };
  const innerStackTokens: IStackTokens = { childrenGap: 2 };
  const scenarioIdTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 400 } };
  const scenarioAlertIcmmapperTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 400 } };
  // const scenarioNameTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 400 } };
  const servicelinesDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioSPLFeaturesDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioKQueryTextStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 600 } };
  const severityDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioPollingFrequencyDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioIcmRoutingRuleDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioIcmCorrelationRuleDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioAlertSuppressionRuleDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioIcmEnrichmentRuleDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const scenarioFalconAutomationDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 400 } };
  const tooltipHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const servicelinesDropdownOptions = [
    { key: 'CustomerExperienceCenters', text: ServiceLines.CustomerExperienceCenters as string },
    { key: 'HybridCloud', text: ServiceLines.HybridCloud as string },
    { key: 'HybridNetworkDCandLabCore', text: ServiceLines.HybridNetworkDCandLabCore as string },
    { key: 'IESFITNetworkServices', text: ServiceLines.IESFITNetworkServices as string },
    { key: 'MSTeamsAdmin', text: ServiceLines.MSTeamsAdmin as string },
    { key: 'NameResolutionandIPSvcs', text: ServiceLines.NameResolutionandIPSvcs as string },
    { key: 'NetworkDefense', text: ServiceLines.NetworkDefense as string },
    { key: 'NetworkLabServices', text: ServiceLines.NetworkLabServices as string },
    { key: 'SiteWiredInfrastructure', text: ServiceLines.SiteWiredInfrastructure as string },
    { key: 'SiteCoreInfrastructure', text: ServiceLines.SiteCoreInfrastructure as string },
    { key: 'SiteWirelessInfrastructure', text: ServiceLines.SiteWirelessInfrastructure as string },
    { key: 'SiteEdgeInfrastructure', text: ServiceLines.SiteEdgeInfrastructure as string },
    { key: 'SiteTreasuryNetwork', text: ServiceLines.SiteTreasuryNetwork as string },
    { key: 'ServiceHealthPlatforms', text: ServiceLines.ServiceHealthPlatforms as string }
  ];
  const severityDropdownOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' }
  ];
  const pollingFrequencyDropdownOptions = [
    { key: '1', text: '5' },
    { key: '2', text: '10' },
    { key: '3', text: '15' },
    { key: '4', text: '20' }
  ];
  const splfeaturesDropdownOptions = [
    { key: 'ICMCorrelation', text: SPLFeatures.ICMCorrelation as string },
    { key: 'ICMFalconAutomation', text: SPLFeatures.ICMFalconAutomation as string },
    { key: 'ICMEnrichment', text: SPLFeatures.ICMEnrichment as string },
    { key: 'AlertSuppression', text: SPLFeatures.AlertSuppression as string },
    { key: 'ICMCreation', text: SPLFeatures.ICMCreation as string }
  ];
  const icmRoutingRuleDropdownOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' }
  ];
  const icmCorrelationRuleDropdownOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' }
  ];
  const alertSuppressionRuleDropdownOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' }
  ];
  const icmEnrichmentRuleDropdownOptions = [
    { key: '1', text: '1' },
    { key: '2', text: '2' },
    { key: '3', text: '3' },
    { key: '4', text: '4' }
  ];
  const scenarioFalconAutomationDropdownOptions = [
    { key: 'true', text: 'True' },
    { key: 'false', text: 'False' }
  ];

  const runValidations = (): void => {
    if (scenarioName && servicelineDropdownItem && seconds && hours && minutes && days &&
        severityDropdownItem && scenarioFalconAutomationDropdownItem && ((scenarioFalconAutomationDropdownItem.key === 'false') || (scenarioFalconAutomationDropdownItem.key === 'true' && className.length > 0 && eventName.length > 0))) {
      if (servicelineDropdownItem.key !== undefined && severityDropdownItem.key !== undefined) {
        // eslint-disable-next-line max-len
        if ((parseInt(hours) > 0 || parseInt(minutes) > 0 || parseInt(hours) > 0 || parseInt(days) > 0)) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  };

  const runQueryValidations = (): void => {

      setIsValidated(false);
      const expectedColumns = [
          'Title', 'RoutingId', 'Severity', 'ImpactedServices',
          'ImpactStartTime', 'OccuringLocationDeviceName',
          'OccuringLocationDataCenter', 'OccuringDeviceGroup',
          'OccuringServiceInstanceId', 'ServiceResponsible', 'Extras'
      ];

      // Ensure | project is the last statement
      const projectMatch = kquery.match(/\|\s*project\s+([\s\S]*)$/);
      let invalidQuery = false;
      let errorString = '';
      if (!projectMatch) {
          invalidQuery = true;
          errorString += 'Please check the query!';
      } else {
          // Extract column names from project clause
          const projectedColumns = projectMatch[1]
              .split(',')
              .map(col => col.trim());

          // Find missing columns
          const missingColumns = expectedColumns.filter(col => !projectedColumns.includes(col));

          // Find extra columns
          const extraColumns = projectedColumns.filter(col => !expectedColumns.includes(col));

          // Drop empty strings
          const missingColumns1 = missingColumns.filter(col => col !== '');

          // Find extra columns
          const extraColumns1 = extraColumns.filter(col => col !== '');

          if (missingColumns1.length > 0) {
              invalidQuery = true;
              errorString += `Please project the missing columns: {${missingColumns1}}`;
          }

          if (extraColumns1.length > 0) {
              invalidQuery = true;
              if (errorString !== '') {
                  errorString += '\n';
              }
              errorString += `Please remove the following columns: {${extraColumns1}}`;
          }
      }
      setQueryRegexError(errorString);
      setIsRegexCheckFailed(invalidQuery);
  };

  const getOrCreateAlertOnboard = async (accessToken: string): Promise<boolean> => {
    let scenarioId = scenarioName;
    const alertOnboard = await AlertOnboardService.get(accessToken, scenarioId, true);
    if (alertOnboard) {
      alertOnboard.shortName = scenarioName as string;
      alertOnboard.source = scenarioId as string;
      alertOnboard.name = scenarioName as string;
      alertOnboard.scenarioName = scenarioName as string;
      alertOnboard.serviceLineName = (servicelineDropdownItem?.key as ServiceLines);
      alertOnboard.kQuery = kquery ? kquery as string : '';
      alertOnboard.isActive = isActive;
      alertOnboard.customIcmSeverityRule = (severityDropdownItem as IDropdownOption).key?.toString();
      alertOnboard.seconds = parseInt(seconds);
      alertOnboard.minutes = parseInt(minutes);
      alertOnboard.hours = parseInt(hours);
      alertOnboard.days = parseInt(days);
      alertOnboard.modifiedOn = createdOn as Date;
      alertOnboard.customIcmIsFalconAutomation = scenarioFalconAutomationDropdownItem ? scenarioFalconAutomationDropdownItem.key as string : 'false';
      if (scenarioFalconAutomationDropdownItem && scenarioFalconAutomationDropdownItem.key === 'true') {
        alertOnboard.className = className;
        alertOnboard.eventName = eventName;
      }
      const createdAlertOnboard = await AlertOnboardService.createOrUpdate(
        accessToken,
      scenarioId as string,
      [alertOnboard]
      );
      scenarioId = createdAlertOnboard ? createdAlertOnboard[0].shortName : '';
      return true;
    } else {
      const scenarioToOnboard: IAlertOnboard = {
        shortName: scenarioName as string,
        source: scenarioId as string,
        name: scenarioName as string,
        scenarioName: scenarioName as string,
        serviceLineName: (servicelineDropdownItem?.key as ServiceLines),
        kQuery: kquery ? kquery as string : '',
        isActive: isActive,
        customIcmSeverityRule: (severityDropdownItem as IDropdownOption).key?.toString(),
        seconds: parseInt(seconds),
        minutes: parseInt(minutes),
        hours: parseInt(hours),
        days: parseInt(days),
        aiOpsFeatures: 'ICMCreation' as SPLFeatures,
        fieldMappings: '',
        customIcmRoutingRule: '',
        customIcmCorrelationRule: '',
        customAlertSupprRule: '',
        customIcmEnrichment: '',
        customIcmIsFalconAutomation: scenarioFalconAutomationDropdownItem ? scenarioFalconAutomationDropdownItem.key as string : 'false',
        className: scenarioFalconAutomationDropdownItem && scenarioFalconAutomationDropdownItem.key === 'true' ? className : '',
        eventName: scenarioFalconAutomationDropdownItem && scenarioFalconAutomationDropdownItem.key === 'true' ? eventName : '',
        falconTriggered: undefined,
        createdOn: createdOn as Date,
        isNew: true
      };
      const createdAlertOnboard = await AlertOnboardService.createOrUpdate(
        accessToken,
      scenarioId as string,
      [scenarioToOnboard]
      );
      console.log(createdAlertOnboard);
      scenarioId = createdAlertOnboard ? createdAlertOnboard[0].shortName : '';
      return true;
    }
  };

  const validateQuery = async (accessToken: string): Promise<boolean | null> => {
    const queryresult = await AlertOnboardService.validatekqlquery(
      accessToken,
      kquery as string
    );
    return queryresult;
  };
  // #endregion Consts

  // #region Event Handlers

  const onScenarioNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setScenarioName(newValue || '');
  };

  const onServicelineChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setServicelineDropdownItem(option);
  };

  const onScenarioKQueryChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setScenarioKQuery(newValue || '');
  };

  const onAlertIcmMapperChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue1?: string): void => {
    setAlertIcmMapperName(newValue1 || '');
  };

  const onScenarioIsActiveChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    setIsActive(checked === true);
  };

  const onSeverityChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setSeverityDropdownItem(option);
  };

  const onSecondsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setSeconds(newValue || '');
  };

  const onMinutesChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setMinutes(newValue || '');
  };

  const onHoursChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setHours(newValue || '');
  };

  const onDaysChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setDays(newValue || '');
  };

  // const onSPLFeaturesChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
  //  setSPLFeatureDropdownItem(option);
  // };

  // const onIcmRoutingRuleChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
  //  setIcmRoutingRuleDropdownItem(option);
  // };

  // const onIcmCorrelationRuleChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
  //  setIcmCorrelationRuleDropdownItem(option);
  // };

  // const onalertSuppressionRuleChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
  //  setAlertSuppressionRuleDropdownItem(option);
  // };

  // const onIcmEnrichmentChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
  //  setIcmEnrichmentRuleDropdownItem(option);
  // };

  const onIsFalconAutomationChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    setIsFalconAutomation(option);
  };

  const onEventNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setEventName(newValue || '');
  };

  const onClassNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    setClassName(newValue || '');
  };

  const editClicked = (): void => {
    setIsEditable(true);
    navigate('edit');
  };

  const cancelClicked = (): void => {
    navigate('/alertonboard');
  };

  const saveClicked = (): void => {
    if (props.accessToken) {
      setIsSaving(true);
      getOrCreateAlertOnboard(props.accessToken).then((result) => {
          if (result) {
              setIsSaving(false);
              setIsEditable(false);
              if (scenarioFalconAutomationDropdownItem && (scenarioFalconAutomationDropdownItem.key === 'true')) {
                  window.open(process.env.REACT_APP_FALCON_UI_ENDPOINT + `?className=${className}&eventName=${eventName}&serviceName=${servicelineDropdownItem?.key as ServiceLines}`, '_blank');
              }
              navigate('/alertonboard');
          } else {
              setIsSaving(false);
              setIsEditable(false);
              alert('Failed to create new scenario. Please try again.');
          }
      });
    } else {
      alert('Unauthorized. Please login again.');
    }
  };

  const testqueryClicked = (): void => {
    if (props.accessToken) {
      setIsSaving(true);
      validateQuery(props.accessToken).then((result) => {
        setIsSaving(false);
        if (result) {
          setIsValidated(true);
          alert('Query is valid.');
        } else {
          alert('Query syntax is not correct. Please check the query');
        }
      });
    } else {
      alert('Unauthorized. Please login again.');
    }
  };

  const redirectToFalconClicked = (): void => {
      window.open(process.env.REACT_APP_FALCON_UI_ENDPOINT + `?className=${className}&eventName=${eventName}&serviceName=${servicelineDropdownItem?.key as ServiceLines}`, '_blank');
  };

  // #endregion Event Handlers

  useEffect(() => {
    runValidations();
  }, [scenarioName,
    id,
    servicelineDropdownItem,
    seconds,
    minutes,
    hours,
    days,
    isActive,
    severityDropdownItem,
    scenarioFalconAutomationDropdownItem,
    className,
    eventName
  ]);

  useEffect(() => {
    runQueryValidations();
  }, [kquery
  ]);

  useEffect(() => {

    /**
     * Fetches data from ALP APIs
     */
    async function fetchFromService () {
      if (props.accessToken) {
        if (id) {
          const alertOnboard = await AlertOnboardService.get(props.accessToken, id, true);
          if (alertOnboard) {
            setScenarioName(id);
            setServicelineDropdownItem(
              servicelinesDropdownOptions.filter(o => { return o.key === alertOnboard.serviceLineName.toString(); })[0]);
            setScenarioKQuery(alertOnboard.kQuery);
            setSeconds(alertOnboard.seconds.toString());
            setMinutes(alertOnboard.minutes.toString());
            setHours(alertOnboard.hours.toString());
            setDays(alertOnboard.days.toString());
            // setSPLFeatureDropdownItem(splfeaturesDropdownOptions.filter(o => { return o.key === alertOnboard.aiOpsFeatures; })[0]);
            setSeverityDropdownItem(severityDropdownOptions.filter(o => { return o.key === alertOnboard.customIcmSeverityRule; })[0]);
            // setAlertIcmMapperName(alertOnboard.fieldMappings);
            // setIcmRoutingRuleDropdownItem(icmRoutingRuleDropdownOptions.filter(o => { return o.key === alertOnboard.customIcmRoutingRule; })[0]);
            // setIcmCorrelationRuleDropdownItem(
            //  icmCorrelationRuleDropdownOptions.filter(o => { return o.key === alertOnboard.customIcmCorrelationRule; })[0]);
            // setAlertSuppressionRuleDropdownItem(
            //  alertSuppressionRuleDropdownOptions.filter(o => { return o.key === alertOnboard.customAlertSupprRule; })[0]);
            // setIcmEnrichmentRuleDropdownItem(
            //  icmEnrichmentRuleDropdownOptions.filter(o => { return o.key === alertOnboard.customIcmEnrichment; })[0]);
            setIsFalconAutomation(
              scenarioFalconAutomationDropdownOptions.filter(o => { return o.key === alertOnboard.customIcmIsFalconAutomation.toString(); })[0]);
            setClassName(alertOnboard.className.toString());
            setEventName(alertOnboard.eventName.toString());
            setCreatedOn(alertOnboard.createdOn);
            setIsActive(alertOnboard.isActive);
          }
        }
      }
      setIsLoading(false);
    }
    fetchFromService();
  }, [props.accessToken]);

  // #region Render Content

  const loadingContent = (
    <div>
      <ListLoading
        groupCount={ 7 }
        groupSpacing={ 36 }
        rowCount={ 2 }
        rowSpacing={ 18 }
        shimmerSize={ 18 }
        rowRelativeLengths={ [3, 10] }
        rowMaxLengthInPercentage={ 70 } />
    </div>
  );

  const mainContent = (
    <Stack tokens={ stackTokens }>
      <Text variant='xLarge'>{ id ? scenarioName : 'Create New Scenario' }</Text>
      <div>
              {
                  isEditable
                    ? <></>
                    : <ActionButton iconProps={editIcon} allowDisabledFocus onClick={editClicked}>Edit</ActionButton>
              }
              {
                  id && scenarioFalconAutomationDropdownItem && scenarioFalconAutomationDropdownItem.key === 'true'
                    ? <ActionButton iconProps={redirectIcon} allowDisabledFocus onClick={redirectToFalconClicked}>Configure Falcon Scenario</ActionButton>
                    : <></>
              }
      </div>
      {
         <div>
          <Stack.Item>
            <Stack tokens={ innerStackTokens }>
              <Stack horizontal={ true }>
                <Text variant='medium'>
                  Scenario Name
                </Text>
                <TooltipHost
                  content={ scenarioIdTooltipContent }
                  id={ scenarioIdToolTip }
                  calloutProps={ scenarioIdTooltipCalloutProps }
                  closeDelay={ 500 }
                  styles={ tooltipHostStyles }>
                  <Text
                    variant='small'
                    aria-describedby={ scenarioIdToolTip }
                    id={ scenarioIdId }>
                    &nbsp;&nbsp;&#x1F6C8;
                  </Text>
                </TooltipHost>
              </Stack>
              <TextField
                key='scenarioName'
                required
                disabled={ !isEditable }
                styles={ scenarioIdTextStyles }
                value={ scenarioName }
                onChange={ onScenarioNameChange }>
              </TextField>
            </Stack>
          </Stack.Item>
        <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              ServiceLine
            </Text>
            <TooltipHost
              id={ servicelineToolTip }
              content={ servicelineTooltipContent }
              calloutProps={ servicelineTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ servicelineToolTip }
                id={ servicelineId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='servicelineName'
            placeholder="Select a Service Line"
            required
            disabled={ !isEditable }
            styles={ servicelinesDropdownStyles }
            options={ servicelinesDropdownOptions }
            selectedKey={ servicelineDropdownItem ? servicelineDropdownItem.key : undefined }
            onChange={ onServicelineChange }
          />
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Query
            </Text>
            <TooltipHost
              content={ scenarioKQueryTooltipContent }
              id={ scenarioKQueryToolTip }
              calloutProps={ scenarioKQueryTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioKQueryToolTip }
                id={ scenarioKQueryId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <div>
          <TextField
            key='KQuery'
            disabled={ !isEditable }
            multiline
            required
            rows={ 5 }
            styles={ scenarioKQueryTextStyles }
            value={ kquery }
            onChange={ onScenarioKQueryChange }>
          </TextField>
          </div>
          <div>
            <Stack.Item>
                {queryRegexError && kquery !== '' && <p style={{ color: 'red' }}>
                    {queryRegexError.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </p>}
                                  <br />
              {!isValidated
                  ? <Stack horizontal tokens={ stackTokens }>
                    <PrimaryButton
                      text={ isSaving ? 'Validating' : 'Validate' }
                      onClick={ testqueryClicked }
                              allowDisabledFocus
                              disabled={!isEditable || isSaving || !isValid || isRegexCheckFailed} />
                  </Stack>
                  : <Stack horizontal tokens={ stackTokens }>
                    <PrimaryButton
                      text='Validated'
                              allowDisabledFocus
                              disabled={true} />
                  </Stack>
              }
            </Stack.Item>
            <Separator></Separator>
          </div>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              &nbsp;Active
            </Text>
            <TooltipHost
              content={ scenarioIsActiveTooltipContent }
              id={ scenarioIsActiveToolTip }
              calloutProps={ scenarioIsActiveTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioIsActiveToolTip }
                id={ scenarioIsActiveId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
            </Stack>
            <Checkbox
              key='isActive'
              required
              disabled={ !isEditable }
              checked={isActive}
              onChange={ onScenarioIsActiveChange }>
            </Checkbox>
          </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Severity
            </Text>
            <TooltipHost
              content={ scenarioSeverityTooltipContent }
              id={ scenarioSeverityToolTip }
              calloutProps={ scenarioSeverityTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioSeverityToolTip }
                id={ scenarioSeverityId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomIcmSeverityRule'
            placeholder="Select incident severity"
            required
            disabled={ severityDropdownOptions.length < 2 || !isEditable }
            styles={ severityDropdownStyles }
            options={ severityDropdownOptions }
            selectedKey={ severityDropdownItem ? severityDropdownItem.key : undefined }
            onChange={ onSeverityChange }
          />
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              Polling Frequency
            </Text>
            <TooltipHost
              content={ scenarioPollingFreqTooltipContent }
              id={ scenarioPollingFreqToolTip }
              calloutProps={ scenarioPollingFreqTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioPollingFreqToolTip }
                id={ scenarioPollingFreqId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack >
            <TextField
                key='Seconds'
                placeholder="Seconds"
                required
                disabled={!isEditable}
                styles={scenarioIdTextStyles}
                value={seconds}
                onChange={onSecondsChange}>
            </TextField>
            <TextField
                key='Minutes'
                placeholder="Minutes"
                required
                disabled={!isEditable}
                styles={scenarioIdTextStyles}
                value={minutes}
                onChange={onMinutesChange}>
            </TextField>
            <TextField
                key='Hours'
                placeholder="Hours"
                required
                disabled={!isEditable}
                styles={scenarioIdTextStyles}
                value={hours}
                onChange={onHoursChange}>
            </TextField>
            <TextField
                key='Days'
                placeholder="Days"
                required
                disabled={!isEditable}
                styles={scenarioIdTextStyles}
                value={days}
                onChange={onDaysChange}>
            </TextField>
        </Stack>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              IsFalconAutomation
            </Text>
            <TooltipHost
              id={ scenarioFalconAutomationToolTip }
              content={ scenarioFalconAutomationTooltipContent }
              calloutProps={ scenarioFalconAutomationTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioFalconAutomationToolTip }
                id={ scenarioFalconAutomationId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomIcmIsFalconAutomation'
            placeholder="Falcon Automation."
            disabled={ !isEditable }
            styles={ scenarioFalconAutomationDropdownStyles }
            options={ scenarioFalconAutomationDropdownOptions }
            selectedKey={ scenarioFalconAutomationDropdownItem ? scenarioFalconAutomationDropdownItem.key : undefined }
            onChange={ onIsFalconAutomationChange }
          />
                          {scenarioFalconAutomationDropdownItem && scenarioFalconAutomationDropdownItem.key === 'true' && <Stack.Item>
                              <Stack tokens={innerStackTokens}>
                                  <Stack horizontal={true}>
                                      <Text variant='medium'>
                                          Class Name
                                      </Text>
                                      <TooltipHost
                                          content={scenarioIdTooltipContent}
                                          id={scenarioIdToolTip}
                                          calloutProps={scenarioIdTooltipCalloutProps}
                                          closeDelay={500}
                                          styles={tooltipHostStyles}>
                                          <Text
                                              variant='small'
                                              aria-describedby={scenarioIdToolTip}
                                              id={scenarioIdId}>
                                              &nbsp;&nbsp;&#x1F6C8;
                                          </Text>
                                      </TooltipHost>
                                  </Stack>
                                  <TextField
                                      key='className'
                                      required
                                      disabled={!isEditable}
                                      styles={scenarioIdTextStyles}
                                      value={className}
                                      onChange={onClassNameChange}>
                                  </TextField>
                              </Stack>
                              <Stack tokens={innerStackTokens}>
                                  <Stack horizontal={true}>
                                      <Text variant='medium'>
                                          Event Name
                                      </Text>
                                      <TooltipHost
                                          content={scenarioIdTooltipContent}
                                          id={scenarioIdToolTip}
                                          calloutProps={scenarioIdTooltipCalloutProps}
                                          closeDelay={500}
                                          styles={tooltipHostStyles}>
                                          <Text
                                              variant='small'
                                              aria-describedby={scenarioIdToolTip}
                                              id={scenarioIdId}>
                                              &nbsp;&nbsp;&#x1F6C8;
                                          </Text>
                                      </TooltipHost>
                                  </Stack>
                                  <TextField
                                      key='eventName'
                                      required
                                      disabled={!isEditable}
                                      styles={scenarioIdTextStyles}
                                      value={eventName}
                                      onChange={onEventNameChange}>
                                  </TextField>
                              </Stack>
                          </Stack.Item>}
        </Stack>
      </Stack.Item>
                  {/* <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              SPLFeatures
            </Text>
            <TooltipHost
              id={ scenarioSPLFeatureToolTip }
              content={ scenarioSPLFeatureTooltipContent }
              calloutProps={ scenarioSPLFeatureTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioSPLFeatureToolTip }
                id={ scenarioSPLFeatureId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='AIOpsFeatures'
            placeholder="Select SmartPipeline Features which you want to avail for the new alert."
            required
            disabled={ !isEditable }
            styles={ scenarioSPLFeaturesDropdownStyles }
            options={ splfeaturesDropdownOptions }
            selectedKey={ splfeatureDropdownItem ? splfeatureDropdownItem.key : undefined }
            onChange={ onSPLFeaturesChange }
          />
        </Stack>
          <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              ICMRoutingRule
            </Text>
            <TooltipHost
              id={ scenarioIcmRoutingRuleTooltip }
              content={ scenarioIcmRoutingRuleTooltipContent }
              calloutProps={ scenarioIcmRoutingRuleTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioIcmRoutingRuleTooltip }
                id={ scenarioIcmRoutingRuleId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomIcmRoutingRule'
            placeholder="Select Incident Routing Rule."
            required
            disabled={ !isEditable }
            styles={ scenarioIcmRoutingRuleDropdownStyles }
            options={ icmRoutingRuleDropdownOptions }
            selectedKey={ icmRoutingRuleDropdownItem ? icmRoutingRuleDropdownItem.key : undefined }
            onChange={ onIcmRoutingRuleChange }
          />
        </Stack>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              ICMCorrelationRule
            </Text>
            <TooltipHost
              id={ scenarioIcmCorrelationRuleTooltip }
              content={ scenarioIcmCorrelationRuleTooltipContent }
              calloutProps={ scenarioIcmCorrelationRuleTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioIcmCorrelationRuleTooltip }
                id={ scenarioIcmCorrelationRuleId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomIcmCorrelationRule'
            placeholder="Select Incident Correlation Rule."
            required
            disabled={ !isEditable }
            styles={ scenarioIcmCorrelationRuleDropdownStyles }
            options={ icmCorrelationRuleDropdownOptions }
            selectedKey={ icmCorrelationRuleDropdownItem ? icmCorrelationRuleDropdownItem.key : undefined }
            onChange={ onIcmCorrelationRuleChange }
          />
        </Stack>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              ICMEnrichmentRule
            </Text>
            <TooltipHost
              id={ scenarioIcmEnrichmentRuleTooltip }
              content={ scenarioIcmEnrichmentRuleTooltipContent }
              calloutProps={ scenarioIcmEnrichmentRuleTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioIcmEnrichmentRuleTooltip }
                id={ scenarioIcmEnrichmentRuleId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomIcmEnrichment'
            placeholder="Select Incident Enrichment Rule."
            disabled={ !isEditable }
            styles={ scenarioIcmEnrichmentRuleDropdownStyles }
            options={ icmEnrichmentRuleDropdownOptions }
            selectedKey={ icmEnrichmentRuleDropdownItem ? icmEnrichmentRuleDropdownItem.key : undefined }
            onChange={ onIcmEnrichmentChange }
          />
        </Stack>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              AlertSuppressionRule
            </Text>
            <TooltipHost
              id={ scenarioAlertSuppressionRuleTooltip }
              content={ scenarioAlertSuppressionRuleTooltipContent }
              calloutProps={ scenarioAlertSuppressionRuleTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioAlertSuppressionRuleTooltip }
                id={ scenarioAlertSuppressionRuleId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomAlertSupprRule'
            placeholder="Select Alert Suppression Rule."
            disabled={ !isEditable }
            styles={ scenarioAlertSuppressionRuleDropdownStyles }
            options={ alertSuppressionRuleDropdownOptions }
            selectedKey={ alertSuppressionRuleDropdownItem ? alertSuppressionRuleDropdownItem.key : undefined }
            onChange={ onalertSuppressionRuleChange }
          />
        </Stack>
        <Stack tokens={ innerStackTokens }>
          <Stack horizontal={ true }>
            <Text variant='medium'>
              IsFalconAutomation
            </Text>
            <TooltipHost
              id={ scenarioFalconAutomationToolTip }
              content={ scenarioFalconAutomationTooltipContent }
              calloutProps={ scenarioFalconAutomationTooltipCalloutProps }
              closeDelay={ 500 }
              styles={ tooltipHostStyles }>
              <Text
                variant='small'
                aria-describedby={ scenarioFalconAutomationToolTip }
                id={ scenarioFalconAutomationId }>
                &nbsp;&nbsp;&#x1F6C8;
              </Text>
            </TooltipHost>
          </Stack>
          <Dropdown
            key='CustomIcmIsFalconAutomation'
            placeholder="Falcon Automation."
            disabled={ !isEditable }
            styles={ scenarioFalconAutomationDropdownStyles }
            options={ scenarioFalconAutomationDropdownOptions }
            selectedKey={ scenarioFalconAutomationDropdownItem ? scenarioFalconAutomationDropdownItem.key : undefined }
            onChange={ onIsFalconAutomationChange }
          />
        </Stack> */}
      </div>
      }
          <div>
            <Stack.Item>
              <Stack horizontal tokens={ stackTokens }>
                <DefaultButton text='Cancel' onClick={ cancelClicked } allowDisabledFocus />
                <PrimaryButton
                  text={ isSaving ? 'Saving' : 'Save' }
                  onClick={ saveClicked }
                          allowDisabledFocus
                          disabled={!isEditable || isSaving || !isValid || !isValidated} />
              </Stack>
            </Stack.Item>
            <Separator></Separator>
          </div>
    </Stack>
  );

  // #endregion Render Content

  return (
    <div>
      {
        isLoading
          ? loadingContent
          : mainContent
      }
    </div>
  );
};
